import {get} from "@/utils/objects"

export default {
    data() {
        return {
            validationErrors: null
        }
    },

    methods: {
        isFieldValid(fieldName) {
            if (this.validationErrors === null)
                return null

            const fieldValue = get(this.validationErrors, fieldName)
            if (fieldValue)
                return false
            return null
        },

        getFieldErrors: function (fieldName) {
            if (this.isFieldValid(fieldName) === false) {
                const fieldValue = get(this.validationErrors, fieldName)
                return fieldValue.join(' ')
            }
            return ''
        },

        getPopulatedValues(obj) {
            let formData = Object.assign({}, obj)
            Object.keys(formData).forEach(k => (!formData[k] && formData[k] !== undefined) && delete formData[k])
            return formData
        }
    }
}