<template>
  <CCard>
    <CCardHeader>
      <h5 class="my-2">Company</h5>
      <div class="card-header-actions">
        <PButton v-if="showSaveButton" :loading="saveButtonLoading" class="btn btn-primary btn-sm ml-2"
                 @click="$emit('save-button-clicked')">
          <translate>Save</translate>
        </PButton>
      </div>
    </CCardHeader>

    <CCardBody class="px-0">

        <CContainer fluid class="grid-stripped">

            <PFormRow>
              <template slot="firstColContent">
                <translate>Business name</translate>
              </template>

              <template slot="secondColContent">
                <CInput v-model="value.company_name">
                  <template slot="append">
                    <div class="invalid-feedback" style="display: block" v-if="isFieldValid('company_name') === false">
                      {{ getFieldErrors('company_name') }}
                    </div>
                  </template>
                </CInput>
              </template>
            </PFormRow>

            <PFormRow>
              <template slot="firstColContent">
                <translate>VAT code</translate>
              </template>

              <template slot="secondColContent">
                <CInput v-model="value.vat_number">
                  <template slot="append">
                    <div class="invalid-feedback" style="display: block" v-if="isFieldValid('vat_number') === false">
                      {{ getFieldErrors('vat_number') }}
                    </div>
                  </template>
                </CInput>
              </template>
            </PFormRow>

            <PFormRow>
              <template slot="firstColContent">
                <translate>Tax code</translate>
              </template>

              <template slot="secondColContent">
                <CInput v-model="value.tax_id">
                  <template slot="append">
                    <div class="invalid-feedback" style="display: block" v-if="isFieldValid('tax_id') === false">
                      {{ getFieldErrors('tax_id') }}
                    </div>
                  </template>
                </CInput>
              </template>
            </PFormRow>

            <PFormRow>
              <template slot="firstColContent">
                <translate>Phone number</translate>
              </template>

              <template slot="secondColContent">
                <CInput v-model="value.company_phone_number">
                  <template slot="append">
                    <div class="invalid-feedback" style="display: block"
                         v-if="isFieldValid('company_phone_number') === false">
                      {{ getFieldErrors('company_phone_number') }}
                    </div>
                  </template>
                </CInput>
              </template>
            </PFormRow>

            <PFormRow>
              <template slot="firstColContent">
                <translate>PEC certified email</translate>
              </template>

              <template slot="secondColContent">
                <CInput v-model="value.email_certified">
                  <template slot="append">
                    <div class="invalid-feedback" style="display: block"
                         v-if="isFieldValid('email_certified') === false">
                      {{ getFieldErrors('email_certified') }}
                    </div>
                  </template>
                </CInput>
              </template>
            </PFormRow>

            <PFormRow>
              <template slot="firstColContent">
                <translate>SDI</translate>
              </template>

              <template slot="secondColContent">
                <CInput v-model="value.sdi_code">
                  <template slot="append">
                    <div class="invalid-feedback" style="display: block" v-if="isFieldValid('sdi_code') === false">
                      {{ getFieldErrors('sdi_code') }}
                    </div>
                  </template>
                </CInput>
              </template>
            </PFormRow>

            <PFormRow>
              <template slot="firstColContent">
                <translate>IBAN</translate>
              </template>
              <template slot="secondColContent">
                <CInput v-model="value.iban">
                  <template slot="append">
                    <div class="invalid-feedback" style="display: block" v-if="isFieldValid('iban') === false">
                      {{ getFieldErrors('iban') }}
                    </div>
                  </template>
                </CInput>
              </template>
            </PFormRow>

            <PFormRow>
              <template slot="firstColContent">
                <translate>BIC</translate>
              </template>
              <template slot="secondColContent">
                <CInput v-model="value.bic">
                  <template slot="append">
                    <div class="invalid-feedback" style="display: block" v-if="isFieldValid('bic') === false">
                      {{ getFieldErrors('bic') }}
                    </div>
                  </template>
                </CInput>
              </template>
            </PFormRow>

        </CContainer>

    </CCardBody>
  </CCard>
</template>


<script>
// import {gmapApi} from 'vue2-google-maps'
import formValidationMixin from "@/mixins/formValidationMixin"
// import GoogleMap from "@/components/GoogleMap"
import PFormRow from "@/components/PFormRow"
import PButton from "@/components/PButton"

export default {
  name: "CompanyCard",

  mixins: [formValidationMixin],

  components: {
    PFormRow,
    PButton,
    // GoogleMap
  },

  props: {
    value: {
      type: Object
    },

    showSaveButton: {
      type: Boolean
    },

    saveButtonLoading: {
      type: Boolean,
      default: false
    },

    errors: {  // era validationErrors
      type: Object
    },
  },

  // data() {
  //     return {
  //         validationErrors: null,
  //     }
  // },

  watch: {
    errors: {
      handler: function (newVal) {
        this.validationErrors = newVal
      },
      immediate: true
    }
  },

  computed: {
    markers() {
      if (this.value.lat === null && this.value.lng === null)
        return null

      return [
        {
          position: {
            lat: this.value.lat,
            lng: this.value.lng
          },
          formatted_address: this.value.place_address
        }
      ]
    }
    //     google: gmapApi
  },

  methods: {
    updatePlace(place) {
      const googleFields = [
        'locality', 'route', 'street_number', 'postal_code', 'administrative_area_level_3',
        'administrative_area_level_2', 'administrative_area_level_2_short_name',
        'administrative_area_level_1', 'administrative_area_level_1_short_name',
        'country', 'country_short_name'
      ]

      googleFields.forEach((gField) => {
        const addressComponent = place.address_components.find((c) => c.types['0'] === gField)
        if (addressComponent) {
          this.value[gField] = gField.endsWith('_short_name') ? addressComponent.short_name : addressComponent.long_name
        } else {
          this.value[gField] = ""
        }
      })

      this.value['lat'] = place.geometry.location.lat()
      this.value['lng'] = place.geometry.location.lng()

      this.$emit('input', this.value)
    },

    // isFieldValid(fieldName) {
    //     if (this.validationErrors === null)
    //         return null
    //
    //     const fieldValue = get(this.validationErrors, `${fieldName}`)
    //     if (fieldValue)
    //         return false
    //     return null
    // },
    //
    // getFieldErrors: function (fieldName) {
    //     if (this.isFieldValid(fieldName) === false) {
    //         const fieldValue = get(this.validationErrors, `${fieldName}`)
    //         return fieldValue.join(' ')
    //     }
    //     return ''
    // },
  }
}
</script>

<style>
.input-group-append {
  white-space: pre-wrap;
}
</style>
