<template>
  <div>
    <h1 class="main-header">{{ title }}</h1>

    <ViewLoadingMessage :loading="isLoading"/>

    <template v-if="photoService">
      <CCard v-if="isEditMode">
        <CCardBody class="p-0">
          <CContainer fluid class="grid-stripped">
            <PFormRow>
              <template slot="firstColContent">
                <translate>Active</translate>
              </template>

              <template slot="secondColContent">
                <CSwitchConfirm
                    class="mx-1" color="success" variant="3d" size="sm"
                    :modal-title="this.$gettext('Status change confirmation')"
                    :checked="photoService.is_active" @click-confirm="saveActivationStatus">

                  <template #modal-body-status-will-true>
                    <translate>You are about to make the photo service active! Are you sure?</translate>
                  </template>
                  <template #modal-body-status-will-false>
                    <translate>You are about to deactivate the photo service! Are you sure?</translate>
                  </template>
                </CSwitchConfirm>
              </template>
            </PFormRow>

            <PFormRow class="mt-2">
              <template slot="firstColContent">
                <translate>Created at</translate>
              </template>

              <template slot="secondColContent">
                {{ photoService.created_at|formatDateTime }}
              </template>
            </PFormRow>

            <PFormRow class="mt-3" v-if="photoService.created_by">
              <template slot="firstColContent">
                <translate>Created by</translate>
              </template>

              <template slot="secondColContent">
                {{ photoService.created_by.email }}<br>
                {{ photoService.created_by.first_name }} {{ photoService.created_by.last_name }}
              </template>
            </PFormRow>
          </CContainer>
        </CCardBody>
      </CCard>

      <CompanyCard v-model="photoService"
                   :errors="companyValidationErrors"
                   :show-save-button="true"
                   :save-button-loading="savingCompany"
                   @save-button-clicked="saveCompany"
      />
    </template>
  </div>
</template>

<script>
import PhotoServiceService from "@/services/photo_service.service"
import CompanyCard from "@/components/CompanyCard"
import formValidationMixin from "@/mixins/formValidationMixin"
import ViewLoadingMessage from "@/components/ViewLoadingMessage"
import CSwitchConfirm from "@/components/CSwitchConfirm"
import PFormRow from "@/components/PFormRow"

export default {
  name: "PhotoServiceDetail",

  mixins: [formValidationMixin],

  components: {
    ViewLoadingMessage,
    CompanyCard,
    CSwitchConfirm,
    PFormRow
  },

  data() {
    return {
      isLoading: false,
      photoService: null,
      savingCompany: false,
      companyValidationErrors: null,
      isActive: false,
    }
  },

  mounted() {
    const photoServiceId = this.$route.params['photoServiceId']

    if (photoServiceId) {
      this.fetchPhotoService(photoServiceId)
    } else {
      const photoService = {
        "is_active": true,
        "company_name": "",
        "company_phone_number": null,
        "postal_code": null,
        "vat_number": "",
        "tax_id": "",
        "iban": "",
        "bic": "",
        "sdi_code": "",
        "email_certified": "",
        "country": null,
        "country_short_name": "it",
        "administrative_area_level_1": null,
        "administrative_area_level_1_short_name": null,
        "administrative_area_level_2": null,
        "administrative_area_level_2_short_name": null,
        "administrative_area_level_3": null,
        "locality": null,
        "street_number": null,
        "route": null,
        "lat": null,
        "lng": null,
        "place_id": null
      }

      this.$set(this, 'photoService', photoService)
    }
  },

  computed: {
    isEditMode() {
      return this.$route.params['photoServiceId'] !== undefined
    },

    title() {
      if (this.isEditMode)
        return this.$gettext('Edit photo service')
      return this.$gettext('New photo service')
    }
  },

  methods: {
    fetchPhotoService(photoServiceId) {
      this.isLoading = true
      PhotoServiceService.retrievePhotoService(photoServiceId)
          .then((response) => {
            this.$set(this, 'photoService', response.data)
            this.isActive = this.photoService.is_active
            this.isLoading = false
          })
    },

    saveActivationStatus(e) {
      console.log('saveActivationStatus', e)
      this.isActive = e
      PhotoServiceService.updatePhotoService(this.photoService.id, {
        'is_active': this.isActive
      })
          .then((response) => {
            console.log('save() success!', response)
            this.notifyMessage({
              text: e ? this.$gettext('Photo service activated') : this.$gettext('Photo service deactivated'),
              messageType: 'success'
            })
          })
          .catch((err) => {
            console.log('saveActivationStatus() error', err.response.data)
          })
    },

    saveCompany() {
      console.log('saveCompany')

      this.companyValidationErrors = null

      if (this.isEditMode) {
        PhotoServiceService.updatePhotoService(this.photoService.id, this.photoService)
            .then(response => {
              console.log('updatePhotoService response', response)
              this.savingCompany = false
              this.notifyMessage({text: this.$gettext('Photo Service created successfully'), messageType: 'success'})
            })
            .catch(err => {
              this.loading = false
              console.log('save() error', err.response.data)
              this.companyValidationErrors = err.response.data
              this.savingCompany = false
              this.notifyMessage({
                text: this.$gettext('Warning: error in filling in the fields'),
                messageType: 'warning'
              })
            })
      } else {
        PhotoServiceService.createPhotoService(this.photoService)
            .then(response => {
              console.log('createPhotoService response', response)
              this.savingCompany = false
              this.notifyMessage({text: this.$gettext('Photo Service updated successfully'), messageType: 'success'})
              this.$router.push({name: 'photoServiceDetail', params: {photoServiceId: response.data.id}})
            })
            .catch(err => {
              this.loading = false
              console.log('save() error', err.response.data)
              this.companyValidationErrors = err.response.data
              this.savingCompany = false
              this.notifyMessage({
                text: this.$gettext('Warning: error in filling in the fields'),
                messageType: 'warning'
              })
            })
      }
    },
  }
}
</script>