<template>

    <CRow class="">
        <CCol :sm="firstColSm" lg="3">
            <label><slot name="firstColContent"></slot></label>
        </CCol>

        <CCol :sm="secondColSm" lg="4">
            <slot name="secondColContent"></slot>
        </CCol>
    </CRow>
</template>

<script>
export default {
    name: "PFormRow",

    props: {
        firstColSm: {
            type: String,
            default: '5'
        },

        secondColSm: {
            type: String,
            default: '7'
        },
    }
}
</script>
