<template>
    <div>
        <label :class="classList">
            <input
                    v-bind="$attrs"
                    :type="type"
                    :checked="state"
                    class="c-switch-input form-check-input"
                    @click="openModal"
            >
            <span
                    :data-checked="labelOn"
                    :data-unchecked="labelOff"
                    class="c-switch-slider"
            ></span>
        </label>
        <CModal :title="modalTitle" :show.sync="showModal">
            <slot name="modal-body-status-will-true" v-if="stateRequested === true"></slot>
            <slot name="modal-body-status-will-false" v-else></slot>
            <template #footer>
                <CButton @click="closeModal" color="secondary" v-translate>Cancel</CButton>
                <CButton @click="confirm" color="primary" v-translate>Confirm</CButton>
            </template>
        </CModal>
    </div>
</template>

<script>
    export default {
        name: 'CSwitchConfirm',
        inheritAttrs: false,
        props: {
            color: String,
            variant: {
                type: String,
                validator: value => ['', 'outline', 'opposite', '3d'].includes(value)
            },
            size: {
                type: String,
                validator: value => ['', 'lg', 'sm'].includes(value)
            },
            shape: {
                type: String,
                validator: value => ['', 'pill', 'square'].includes(value)
            },
            checked: Boolean,
            labelOn: String,
            labelOff: String,
            type: {
                type: String,
                default: 'checkbox',
                validator: type => ['checkbox', 'radio'].includes(type)
            },

            modalTitle: {
                type: String
            }
        },
        data() {
            return {
                state: undefined,
                stateRequested: undefined,
                showModal: false
            }
        },
        // mounted() {
        //
        // },
        watch: {
            checked: {
                immediate: true,
                handler(val) {
                    this.state = val
                }
            }
        },
        computed: {
            classList() {
                const variant = this.variant ? `-${this.variant}` : ''
                return [
                    'c-switch form-check-label',
                    {
                        [`c-switch-${this.size}`]: this.size,
                        [`c-switch-${this.shape}`]: this.shape,
                        [`c-switch${variant}-${this.color}`]: this.color,
                        'c-switch-label': this.labelOn || this.labelOff
                    }
                ]
            }
        },
        methods: {
            // onChange(e) {
            //     this.state = e.target.checked
            //     this.$emit('update:checked', e.target.checked, e)
            // },

            openModal(e) {
                e.preventDefault()
                this.stateRequested = e.target.checked
                this.showModal = true
            },

            closeModal() {
                this.stateRequested = undefined
                this.showModal = false
            },

            confirm() {
                this.state = this.stateRequested
                this.stateRequested = undefined
                this.showModal = false
                this.$emit('click-confirm', this.state)
                // this.$emit('update:checked', this.state)
            }
        }
    }
</script>
